import { useTitle } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { ApplicationState } from '@/enums/ApplicationState'
import { Environment } from '@/enums/Environment'
import type { Settings } from '@/models/Setting'

import { useSettingsStore } from './settings'

export const useAppStore = defineStore('app', () => {
  const product = 'accounts'
  const title = ref('')
  const state = ref<ApplicationState>(ApplicationState.INITIALIZING)
  const isOnline = ref<boolean>(navigator.onLine)
  const environment = ref<Environment>(Environment.PRODUCTION)
  const settings = useSettingsStore()

  function setState(newState: ApplicationState) {
    if (state.value === newState) return
    state.value = newState
  }

  function setting<T extends keyof Settings>(key: T): Settings[T] | undefined {
    return settings.get(key)
  }

  /** Sets the title suffix of the application. This is automatically added to page titles. */
  function setTitle(newTitle: string = '') {
    title.value = newTitle ?? ''
  }

  function setPageTitle(pageTitle: string = '') {
    const baseTitle = `${title.value}`
    if (!pageTitle) useTitle(baseTitle)

    return baseTitle.includes('%')
      ? useTitle(baseTitle.replace('%', pageTitle))
      : pageTitle
        ? useTitle(`${pageTitle} | ${baseTitle}`)
        : useTitle(baseTitle)
  }

  const isInitializing = computed(() => state.value === ApplicationState.INITIALIZING)
  const isInitialized = computed(() => state.value === ApplicationState.INITIALIZED)
  const isReady = computed(() => state.value === ApplicationState.READY)
  const isFatal = computed(() => state.value === ApplicationState.FATAL)
  const isUnknown = computed(() => state.value === ApplicationState.UNKNOWN)
  const isMaintenance = computed(() => state.value === ApplicationState.MAINTENANCE)
  const isOffline = computed(() => state.value === ApplicationState.OFFLINE)

  return {
    state,
    environment,
    isOnline,
    product,
    setState,
    setPageTitle,
    setting,
    isInitializing,
    isInitialized,
    isReady,
    isFatal,
    isUnknown,
    isMaintenance,
    isOffline,
    setTitle,
    title,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
