import { jwtDecode, type JwtPayload } from 'jwt-decode'
import Keycloak from 'keycloak-js'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import useTimer from '@/composables'
import { isLocal } from '@/environments'

export const useTokenStore = defineStore(
  'token',
  () => {
    const access = ref<string | undefined>(undefined)
    const accessDecoded = ref<JwtPayload | undefined>(undefined)
    const refresh = ref<string | undefined>(undefined)
    const timeSkew = ref<number>(0)
    const { start: startTimer, stop: stopTimer, isRunning: isTimerRunning } = useTimer()

    const client = ref<Keycloak>()

    function getClient() {
      if (!client.value) {
        client.value = new Keycloak({
          url: ``,
          realm: ``,
          clientId: ``,
        })
      }

      return client.value
    }

    function setAccess(token: string | undefined) {
      access.value = token
      if (!access.value) return

      accessDecoded.value = jwtDecode(access.value)
    }

    function setTimeSkew() {
      if (accessDecoded.value?.iat) {
        timeSkew.value = Math.floor(Date.now() / 1000) - accessDecoded.value.iat
      }

      if (isLocal) console.log(`[ATABASE] Estimated time difference between browser and server is ${Math.round(timeSkew.value)} seconds`)
    }

    function setRefresh(token: string | undefined) {
      refresh.value = token
    }

    async function setInterval(callback: () => Promise<void>, ms: number): Promise<void> {
      if (!access.value) return

      // Initiate a refresh immediately in case the token has already expired.
      await callback()

      // Start the timer for any additional checks.
      startTimer(callback, ms)
    }

    function clearInterval() {
      stopTimer()
    }

    function clear() {
      clearInterval()
      setAccess(undefined)
      setRefresh(undefined)
    }

    function isExpired(minValidity): boolean {
      if (!access.value) return true
      if (!accessDecoded.value) return true

      const expirationDate = accessDecoded.value.exp
      const timeSkewDate = accessDecoded.value.iat
      if (!expirationDate || !timeSkewDate) return true

      let expiresIn = expirationDate - Math.ceil(Date.now() / 1000) + timeSkew.value // Seconds
      if (minValidity) expiresIn -= minValidity

      if (isLocal) console.log(`[ATABASE] Token expires in ${Math.round(expiresIn)} seconds.`)

      return expiresIn <= 0
    }

    return {
      access,
      refresh,
      getClient,
      setAccess,
      setRefresh,
      setTimeSkew,
      isExpired,
      isTimerRunning,
      clear,
      setInterval,
      clearInterval,
    }
  },
  {
    persist: {
      pick: ['access', 'refresh', 'timeSkew'],
      afterHydrate(context) {
        context.store.setAccess(context.store.access)
        context.store.setRefresh(context.store.refresh)
      },
      storage: localStorage,
    },
  },
)
