import type { NavigationGuardWithThis } from 'vue-router'

import { useAuthDriver } from '@/auth'
import { useAuthStore } from '@/stores/auth'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AuthMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const auth = useAuthStore()

  await useAuthDriver().driver.session()
  auth.setLoading(false)

  return true
}

export default AuthMiddleware
