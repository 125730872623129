import { getQueryParameter } from '@atabix/core'
import type { NavigationGuardWithThis } from 'vue-router'

/**
 * Middleware to ensure that the version query parameter is always present when navigating to another route.
 * Having it visible in the URL makes it easier to share the link with others and makes it more obvious.
 **/
const VersionMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const versionId = getQueryParameter('version') || undefined

  if (versionId && !to.query.version) {
    return next({ path: to.path, query: { ...to.query, version: from.query.version } })
  }
}

export default VersionMiddleware
