<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  messages: {
    en: {
      title: 'Oops!',
      description: 'The request page does not exist.',
      back: 'Go back',
    },
    nl: {
      title: 'Oeps!',
      description: 'De opgevraagde pagina bestaat niet.',
      back: 'Ga terug',
    },
  },
})
</script>

<template>
  <div class="my-8 flex flex-col items-center gap-4">
    <h1 class="text-6xl">
      {{ t('title') }}
    </h1>
    <p>{{ t('description') }}</p>
  </div>
</template>
