<script setup lang="ts">
import {
  ADialogTeleporter,
  ALoginLayout,
  type ALoginLayoutProperties,
  type ALoginState,
  type ForgotPasswordFormPayload,
  type LoginFormPayload,
  type ResetPasswordFormPayload,
} from '@atabix/vue-ui'
import { createGtm } from '@gtm-support/vue-gtm'
import { useDark, useToggle } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAuthDriver } from '@/auth'
import TokenDriver, { OAuth2TokenError, type RegisterPayload, UnprocessableAuthError } from '@/auth/TokenDriver'
import AuthLayout from '@/components/AuthLayout.vue'
import ErrorLayout from '@/components/ErrorLayout.vue'
import LoginIdentities from '@/components/LoginIdentities.vue'
import { getApp } from '@/main'
import { getGtmConfig } from '@/plugins/gtm'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useIdleStore } from '@/stores/idle'
import { useSettingsStore } from '@/stores/settings'
import SplashView from '@/views/SplashView.vue'

import RegisterForm, { type RegisterFormPayload, type RegisterFormSubmitData } from './components/auth/RegisterForm.vue'
import LanguageSelect from './components/LanguageSelect.vue'
import type { Settings, SettingsRegistration, SettingsRegistrationField } from './models/Setting'

const { t } = useI18n({
  messages: {
    en: {
      title: 'MyPortal',
      login: 'Login',
      'login identity description': 'Click below to continue with logging in.',
      idle: 'Idle',
      'idle description': 'It appears that you are currently inactive. You will be automatically logged out if you do not click this message.',
    },
    nl: {
      title: 'MijnPortaal',
      login: 'Inloggen',
      'login identity description': 'Klik hieronder om verder te gaan met inloggen.',
      idle: 'Inactief',
      'idle description': 'Het lijkt erop dat je momenteel inactief bent. Je wordt automatisch uitgelogd als je dit bericht niet klikt.',
    },
    de: {
      title: 'MeinPortal',
      login: 'Einloggen',
      'login identity description': 'Klicken Sie unten, um mit der Anmeldung fortzufahren.',
      idle: 'Leerlauf',
      'idle description': 'Sie sind derzeit offenbar inaktiv. Wenn Sie diese Nachricht nicht schließen, werden Sie automatisch abgemeldet.',
    },
  },
})

const auth = useAuthStore()
const idle = useIdleStore()
const app = useAppStore()
const settings = useSettingsStore()
const isDark = useDark()
const [isLoading, setLoading] = useToggle(false)
const errorMessage = ref<string | undefined>()
const message = ref<ALoginLayoutProperties['message']>({
  type: 'success',
  title: '',
  description: '',
})
const state = ref<ALoginState>('login')
const computedSettings = computed((): Partial<Settings> => settings.settings)
const isCredentialsAllowed = computed((): boolean => computedSettings.value?.is_credentials_allowed ?? true)
const loginDescription = computed((): string | undefined => (isCredentialsAllowed.value ? undefined : t('login identity description')))
const settingsRegistration = computed((): SettingsRegistration | undefined => computedSettings.value?.registration)
const settingsRegistrationFields = computed((): SettingsRegistrationField[] | undefined => computedSettings.value?.registration?.fields)
const initialSettingsRegistrationPayload = computed((): RegisterFormPayload | undefined =>
  settingsRegistration.value?.fields?.reduce((accumulator, field): RegisterFormPayload => {
    accumulator[field.id] = undefined
    return accumulator
  }, {} as RegisterFormPayload),
)
const canRegister = computed((): boolean => !!initialSettingsRegistrationPayload.value)
const settingsPrivacyPolicyUrl = computed((): string | undefined => computedSettings.value?.privacy)

async function onSubmitAuth(data: LoginFormPayload): Promise<void> {
  setLoading(true)

  try {
    await useAuthDriver().login(data.username, data.password)
    errorMessage.value = undefined
  } catch (error: unknown) {
    console.error(error)
    errorMessage.value = error instanceof OAuth2TokenError ? error.message : t('unexpected error occurred')
  } finally {
    setTimeout((): void => {
      setLoading(false)
    }, 1000)
  }
}

async function onSubmitForgot(event: ForgotPasswordFormPayload): Promise<void> {
  setLoading(true)

  try {
    await TokenDriver.forgotPassword(event.username)
    errorMessage.value = undefined
    event.setSuccess()
  } catch (error: unknown) {
    console.error(error)
    errorMessage.value = error instanceof UnprocessableAuthError ? error.message : t('unexpected error occurred')
    event.setError()
  } finally {
    setLoading(false)
  }
}

async function onSubmitRegister(event: RegisterFormSubmitData): Promise<void> {
  if (!settingsRegistration.value?.entity_type?.id) return

  setLoading(true)

  const payload: RegisterPayload = {
    fields: Object.entries(event.payload).map(([id, value]) => ({ id, value })),
    type: { id: settingsRegistration.value.entity_type.id },
  }

  try {
    await TokenDriver.register(payload)
    errorMessage.value = undefined
    event.setSuccess()
  } catch (error: unknown) {
    errorMessage.value = error instanceof UnprocessableAuthError ? error.message : t('unexpected error occurred')
    event.setError()
  } finally {
    setLoading(false)
  }
}

async function onSubmitReset(event: ResetPasswordFormPayload): Promise<void> {
  setLoading(true)

  try {
    await TokenDriver.resetPassword(event.username, event.password, event.token)
    errorMessage.value = undefined
    state.value = 'login'

    await onSubmitAuth({
      username: event.username,
      password: event.password,
    })
  } catch (error: unknown) {
    errorMessage.value = error instanceof UnprocessableAuthError ? error.message : t('unexpected error occurred')
    event.setError()
  }

  setLoading(false)
}

function onCancelRegister(): void {
  state.value = 'login'
}

function onErrorIdentity(response: OAuth2TokenError): void {
  errorMessage.value = response.message
}

function onSuccessIdentity(): void {
  errorMessage.value = undefined
}

function onLoadingIdentity(loading: boolean): void {
  setLoading(loading)
}

watch(
  (): boolean => app.isReady,
  (isReady): void => {
    if (isReady) {
      app.setPageTitle(auth.isInitalized ? t('title') : t('login'))

      const googleTagManager = getGtmConfig(useRouter())

      if (googleTagManager) {
        getApp().use(createGtm(googleTagManager))
      }
    }
  },
)

watch(
  (): boolean => auth.isAuthenticated,
  (isAuthenticated): void => {
    if (!isAuthenticated) {
      errorMessage.value = undefined
    }
  },
)

watch(state, (): void => {
  errorMessage.value = undefined
})

watch(
  (): boolean => idle.isIdle,
  (isIdle): void => {
    if (!auth.isAuthenticated) return
    if (!isIdle) return

    idle.prompt(t('idle description'), (): void => {
      useAuthDriver().logout()
    })
  },
)
</script>

<template>
  <main class="relative flex min-h-screen flex-col bg-background-tint">
    <template v-if="app.isReady">
      <ALoginLayout
        v-if="!auth.isAuthenticated"
        v-model="state"
        :background="app.setting('background')"
        :colors="{
          primary: settings.get('colors.primary') || '#0A3D3A',
          background: isDark ? '#161A1D' : '#FFFFFF',
        }"
        :description="loginDescription"
        :error="errorMessage"
        :form="isCredentialsAllowed"
        :loading="isLoading"
        :logo="isDark ? app.setting('logo.dark') : app.setting('logo.light')"
        :message="message"
        :register="canRegister"
        :title="app.title || t('title')"
        @forgot="onSubmitForgot"
        @reset="onSubmitReset"
        @submit="onSubmitAuth"
      >
        <template v-if="(app.setting('identities') || []).length > 0" #other>
          <LoginIdentities @error="onErrorIdentity" @loading="onLoadingIdentity" @success="onSuccessIdentity" />
        </template>

        <template #register>
          <RegisterForm
            v-if="initialSettingsRegistrationPayload && settingsRegistrationFields"
            :fields="settingsRegistrationFields"
            :initial-payload="initialSettingsRegistrationPayload"
            :loading="!!isLoading"
            :privacy-policy-url="settingsPrivacyPolicyUrl"
            @cancel="onCancelRegister"
            @submit="onSubmitRegister"
          />
        </template>

        <template #footer>
          <LanguageSelect
            v-if="!auth.isAuthenticated && (app.isReady || app.isUnknown || app.isFatal || app.isMaintenance)"
            class="ml-auto w-52 pt-4"
          />
        </template>
      </ALoginLayout>

      <AuthLayout v-else />
    </template>

    <SplashView v-if="app.isInitializing" />

    <ErrorLayout v-if="app.isFatal" :description="t('unexpected error occurred')" :title="t('error')" />
    <ErrorLayout v-if="app.isUnknown" :description="t('unknown environment')" :title="t('unknown')" />
    <ErrorLayout v-if="app.isMaintenance" :description="t('maintenance description')" :title="t('maintenance')" />
  </main>

  <ADialogTeleporter />
</template>

<style scoped></style>
