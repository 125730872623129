<template>
  <div class="app-splash">
    <img alt="Logo" class="size-32 animate-bounce select-none" src="/icon.svg" />
  </div>
</template>

<style lang="scss">
.app-splash {
  @apply h-screen w-screen flex flex-col items-center justify-center;
  background-color: #0a3d3a;
}
</style>
