import type { TypedBroadcastChannel } from '@atabix/core'
import { useIdle } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { toast } from 'vue3-toastify'

/** Store that manages the idle state of the application in the context of multiple tabs. */
export const useIdleStore = defineStore('idle', () => {
  /** The amount of time in milliseconds that is allowed to pass before a user is considered idle. */
  const threshold = ref<number>(15 * 60 * 1000) // 15 Minutes

  /** The amount of time in milliseconds that is allowed to pass before the prompt expires after the user is considered idle. */
  const promptThreshold = ref<number>(1 * 60 * 1000) // 1 Minute

  /** The tick rate in milliseconds for syncing the idle state between all tabs. */
  const tick = ref<number>(Math.round(threshold.value / 4))

  /** The local interval timer for executing the idle state between all tabs. */
  const tickInterval = ref<number>(0)

  /** Determines if the current tab is idle or not. */
  const { idle: isTabIdle, lastActive: tabLastActive } = useIdle(threshold.value)

  /** Determines if the application is idle amongst all tabs. */
  const isIdle = ref(false)

  /** Determines if the application is prompting the user to take action. */
  const isPromptingIdle = ref(false)

  /** The last active timestamp in seconds of all tabs. */
  const lastActive = ref<number>(Date.now())

  /** The broadcast channel for the idle state. */
  const channel: TypedBroadcastChannel<IdleBroadcastChannel> = new BroadcastChannel('idle')

  /** Handles the incoming messages from the broadcast channel. */
  channel.addEventListener('message', (event) => {
    if (event.data.type === 'message') onIdleMessage({ ...event.data, source: event.source })
    if (event.data.type === 'prompt') onPromptMessage({ ...event.data })
  })

  /** Sets the idle status for the idle state and broadcasts it. */
  function setIdle(value: boolean) {
    // Disable the idle state if the threshold is set to 0.
    if (threshold.value === 0) return

    const message: IdleBroadcastMessageEvent = { type: 'message', idle: value, timestamp: Date.now(), lastActive: tabLastActive.value }

    // Broadcast the idle state to the current tab and the listening tabs.
    onIdleMessage(message)
    channel.postMessage(message)
  }

  /** Sets the prompt status for the idle state and broadcasts it. */
  function setPrompt(value: boolean) {
    const message: IdleBroadcastPromptEvent = { type: 'prompt', status: value }

    // Broadcast the idle state to the current tab and the listening tabs.
    onPromptMessage(message)
    channel.postMessage(message)
  }

  /** Handles the idle message from the broadcast channel. */
  function onIdleMessage(message: IdleBroadcastMessageEvent) {
    lastActive.value = Math.max(lastActive.value, tabLastActive.value, message.lastActive)

    isIdle.value = lastActive.value < Date.now() - threshold.value
  }

  /** Handles the prompt message from the broadcast channel. */
  function onPromptMessage(message: IdleBroadcastPromptEvent) {
    isPromptingIdle.value = message.status
    if (!isPromptingIdle.value) {
      toast.remove('idle')
    }
  }

  /** Prompt the user with a message when the application is idle.
   * If the message is ignored the expiry action will run automatically.
   **/
  function prompt(message: string, onPromptExpired: () => void, timeout?: number | undefined) {
    toast.warn(message, {
      toastId: 'idle',
      pauseOnFocusLoss: false,
      autoClose: timeout || promptThreshold.value,
      closeButton: false,
      pauseOnHover: false,
      onOpen: () => setPrompt(true),
      onClose: () => {
        if (isPromptingIdle.value) {
          onPromptExpired()
          setPrompt(false)
        }
      },
      onClick: () => {
        setPrompt(false)
      },
      position: toast.POSITION.TOP_CENTER,
    })
  }

  watch(isTabIdle, (value) => {
    setIdle(value)
  })

  // Watch the threshold and update the tick interval accordingly.
  watch(threshold, () => {
    if (tickInterval.value) clearInterval(tickInterval.value)

    tick.value = Math.round(threshold.value / 4)

    // Set the tick interval to the threshold divided by 4 but no less than 5 seconds.
    tickInterval.value = setInterval(
      () => {
        setIdle(isTabIdle.value)
      },
      Math.max(tick.value, 5000),
    ) as unknown as number
  })

  return { isTabIdle, isIdle, prompt, isPromptingIdle, threshold }
})

type IdleBroadcastMessageEvent = {
  type: 'message'
  idle: boolean
  timestamp: number
  lastActive: number
  source?: MessageEventSource | null
}

type IdleBroadcastPromptEvent = {
  type: 'prompt'
  status: boolean
}

type IdleBroadcastChannel = IdleBroadcastMessageEvent | IdleBroadcastPromptEvent
