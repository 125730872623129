<script setup lang="ts">
import type { ListItemInterface } from '@atabix/vue-ui'
import { AContainer, AMenu } from '@atabix/vue-ui'
import { useDark } from '@vueuse/core'
import { CogOutlineIcon, EmailOutlineIcon, FileDocumentOutlineIcon, HomeOutlineIcon, TextBoxEditOutlineIcon } from 'mdi-vue3'
import { computed, markRaw } from 'vue'
import { useI18n } from 'vue-i18n'

import PageHeader from '@/components/PageHeader.vue'
import { RouteName } from '@/enums'
import { PageEnum } from '@/enums/PageEnum'
import { useAppStore } from '@/stores/app'
import { usePagesStore } from '@/stores/pages'

const { t } = useI18n({
  messages: {
    en: {
      title: 'MyPortal',
    },
    nl: {
      title: 'MijnPortaal',
    },
    de: {
      title: 'MeinPortal',
    },
  },
})

const app = useAppStore()
const pages = usePagesStore()
const isDark = useDark()

const menu = computed<ListItemInterface[]>(() => {
  if (pages.items.length <= 0) {
    return []
  }

  return [
    {
      title: t(`pages.${RouteName.HOME}`),
      icon: markRaw(HomeOutlineIcon),
      to: { name: RouteName.HOME },
      visible: pages.has(PageEnum.DASHBOARD),
    },
    {
      title: t(`pages.${RouteName.MATTERS_INCOMPLETE}`),
      icon: markRaw(FileDocumentOutlineIcon),
      to: { name: RouteName.MATTERS_INCOMPLETE },
      visible: pages.has(PageEnum.CASES_BUSY) || pages.has(PageEnum.CASES_FINISHED),
    },
    {
      title: t(`pages.${RouteName.FORMS}`),
      icon: markRaw(TextBoxEditOutlineIcon),
      to: { name: RouteName.FORMS },
      visible: pages.has(PageEnum.FORMS) || pages.has(PageEnum.FORMS_DRAFT_SUBMISSIONS),
    },
    {
      title: t(`pages.${RouteName.MESSAGES}`),
      icon: markRaw(EmailOutlineIcon),
      to: { name: RouteName.MESSAGES },
      visible: true,
    },
    {
      title: t(`pages.${RouteName.SETTINGS}`),
      icon: markRaw(CogOutlineIcon),
      to: { name: RouteName.SETTINGS },
      visible: true,
    },
  ]
})
</script>

<template>
  <PageHeader :items="menu" :logo="isDark ? app.setting('logo.dark') : app.setting('logo.light')" :title="app.title || t('title')" />
  <AContainer class="py-9">
    <div class="flex flex-row gap-8">
      <AMenu class="hidden min-w-[216px] md:block" :items="menu" />
      <div class="grow">
        <RouterView />
      </div>
    </div>
  </AContainer>
</template>
