import { computed, ref } from 'vue'

export default function useTimer() {
  const intervalId = ref<NodeJS.Timeout | undefined>(undefined)

  function start(callback: () => void, interval: number) {
    intervalId.value = setInterval(callback, interval)
  }

  function stop() {
    clearInterval(intervalId.value)
  }

  const isRunning = computed(() => !!intervalId.value)

  return {
    start,
    stop,
    isRunning,
  }
}
