/* eslint-disable @typescript-eslint/no-explicit-any */
// import { setLocale } from '@vee-validate/i18n'
import axios from 'axios'
import type { App } from 'vue'
import { nextTick } from 'vue'
import { createI18n as createBaseI18n, type I18n } from 'vue-i18n'

import { useSettingsStore } from '@/stores/settings'

export const SUPPORT_LOCALES = ['en', 'nl', 'de'] as const
export type Locale = (typeof SUPPORT_LOCALES)[number]

const numberFormats: any = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  jp: {
    currency: {
      style: 'currency',
      currency: 'JPY',
      useGrouping: true,
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      currencyDisplay: 'symbol',
    },
    decimal: {
      style: 'decimal',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
}

const datetimeFormats: any = {
  en: {
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
  jp: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  nl: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
  de: {
    short: { year: 'numeric', month: 'numeric', day: 'numeric' },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
}

// eslint-disable-next-line unicorn/no-object-as-default-parameter
export const createI18n = (options: i18nOptions = { locale: 'nl' }) => {
  return createBaseI18n({
    globalInjection: true,
    legacy: false,
    fallbackWarn: false,
    missingWarn: false,
    fallbackLocale: 'en',
    locale: options.locale,
    numberFormats,
    datetimeFormats,
  })
}

export const useI18n = (app: App, i18n: I18n, locale: Locale) => {
  app.use(i18n)
  initDefaultLocale()
  loadLocaleMessages(i18n, locale)
}

export function initDefaultLocale() {
  const settings = useSettingsStore()

  settings.setLocale()
}

export function setI18nLocale(locale: Locale) {
  if (typeof i18n.global.locale === 'object') {
    i18n.global.locale.value = locale
  }

  loadLocaleMessages(i18n, locale)

  document.querySelector('html')?.setAttribute('lang', locale)
  axios.defaults.headers.common['Accept-Language'] = locale

  // setLocale(locale) // @TODO
}

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
  // load locale messages with dynamic import
  const messages = await import(/* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export function getCurrentLocale(): Locale {
  return i18n.global.locale.value as Locale
}

export type i18nOptions = { locale: Locale }

export const i18n = createI18n({ locale: 'nl' })
