import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { WidgetEnum } from '@/enums/WidgetEnum'
import type { SettingWidget } from '@/models/Setting'
import { useSettingsStore } from '@/stores/settings'

export const useWidgetsStore = defineStore('widgets', () => {
  const isLoading = ref(false)
  const items = ref<SettingWidget[]>([])

  function setItems(data: SettingWidget[] = []) {
    items.value = data
    setLoading(false)
  }

  function setLoading(value: boolean = true) {
    isLoading.value = value
  }

  /** Refreshes the tasks by fetching them from the API. */
  async function refresh(): Promise<void> {
    setLoading(true)
    const settings = useSettingsStore().get('widgets')

    try {
      items.value = settings || []
    } catch {
      items.value = []
    }

    setLoading(false)
  }

  function has(id: WidgetEnum): boolean {
    return items.value.some((item) => item.id === id)
  }

  return { isLoading, items, has, setItems, setLoading, refresh }
})
