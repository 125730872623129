/* eslint-disable unicorn/no-null */
import type { ANavigationProfileInterface } from '@atabix/vue-ui'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import type { User } from '@/models/User'

import { useSettingsStore } from './settings'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const isInitalized = ref(false)
    const isLoading = ref(false)
    const user = ref<User | null>(null)

    function setUser(data?: User) {
      user.value = data || null
      if (user.value?.language) {
        useSettingsStore().setLocale(user.value.language)
      }
    }

    function getUser(): User | null {
      return user.value as User | null
    }

    function setLoading(value: boolean = true) {
      isLoading.value = value
    }

    function setInitalized(value: boolean = true) {
      isInitalized.value = value
    }

    function clear() {
      setUser()
    }

    const isAuthenticated = computed(() => user.value !== null)

    return { user, clear, isAuthenticated, isInitalized, setInitalized, isLoading, setLoading, setUser, getUser }
  },
  {
    persist: { pick: [], storage: localStorage },
  },
)

export type AAuthUser = ANavigationProfileInterface & { first_name: string; last_name: string }
