import type { NavigationGuardWithThis } from 'vue-router'

import { useAuthDriver } from '@/auth'
import AuthMiddleware from '@/router/middleware/AuthMiddleware'
import KeycloakMiddleware from '@/router/middleware/KeycloakMiddleware'
import { useTokenStore } from '@/stores/token'

const GuestMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const token = useTokenStore()
  const driver = useAuthDriver()

  // Immediately perform a session check if the driver is keycloak.
  if (driver.type === 'keycloak') {
    KeycloakMiddleware.call(undefined, to, from, next)
    return true
  }

  if (token.access || token.refresh) {
    await AuthMiddleware.call(undefined, to, from, next)
    return true
  }
}

export default GuestMiddleware
