import type { NavigationGuardWithThis } from 'vue-router'

import { useSettingsStore } from '@/stores/settings'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InitializeSettingsMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const settings = useSettingsStore()

  if (!settings.hasFetched) {
    await settings.refresh()
  }
}

export default InitializeSettingsMiddleware
