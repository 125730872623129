import uniqBy from 'lodash-es/uniqBy'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { Matter, Task } from '@/models'

export const useTasksStore = defineStore('tasks', () => {
  const isLoading = ref(false)
  const items = ref<Task[]>([])
  const count = computed(() => items.value.length)
  const matters = computed<Matter[]>(
    () =>
      uniqBy(
        items.value.map((task) => task.matter),
        'id',
      ) as Matter[],
  )

  function setItems(data: Task[] = []) {
    items.value = data
    setLoading(false)
  }

  function setLoading(value: boolean = true) {
    isLoading.value = value
  }

  /** Refreshes the tasks by fetching them from the API. */
  async function refresh(): Promise<void> {
    setLoading(true)

    try {
      const tasks = await new Task()
        .include(['matter'])
        .filter({
          is_done: false,
        })
        .all()

      items.value = tasks || []
    } catch (error) {
      console.error(error)
      items.value = []
    }

    setLoading(false)
  }

  return { isLoading, items, matters, count, setItems, setLoading, refresh }
})
