<script setup lang="ts">
import { getQueryParameter } from '@atabix/core'
import { AButton } from '@atabix/vue-ui'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

import type { OAuth2TokenResponse } from '@/auth/TokenDriver'
import { OAuth2TokenError } from '@/auth/TokenDriver'
import TokenDriver from '@/auth/TokenDriver'
import type { SettingIdentity } from '@/models/Setting'
import { getCurrentLocale } from '@/plugins/i18n'
import { useSettingsStore } from '@/stores/settings'

interface LoginIdentitiesEvents {
  /**
   * Emitted when the button is clicked
   */
  (event: 'error', value: OAuth2TokenError): void
  (event: 'success'): void
  (event: 'loading', value: boolean): void
}

const emit = defineEmits<LoginIdentitiesEvents>()

const settings = useSettingsStore()
const isLoading = ref<boolean>(false)

const identities = computed<SettingIdentity[]>(() => settings.get('identities') || [])

async function onClickIdentity(identity: SettingIdentity) {
  const response = await fetch(identity.url, {
    method: 'POST',
  })

  const redirectUrl = await response.json()
  globalThis.location.replace(redirectUrl)
}

onMounted(initialize)

async function initialize() {
  const code = getQueryParameter('code', true)

  if (code) await login(code)
}

async function login(code: string): Promise<void> {
  if (isLoading.value) return

  setLoading(true)
  try {
    const response = await fetch(TokenDriver.url('/customers/token'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({
        grant_type: 'atabase_grant',
        code,
      }),
    })

    const json = await response.json()

    if (!response.ok) throw new OAuth2TokenError(json.error, json.error_description, json.message)
    const data: OAuth2TokenResponse = json

    await TokenDriver.consumeTokenResponse(data)
    await TokenDriver.session()
    emit('success')
  } catch (error) {
    emit('error', error as OAuth2TokenError)
  } finally {
    setLoading(false)
  }
}

function setLoading(value: boolean) {
  isLoading.value = value
  emit('loading', value)
}

onBeforeUnmount(() => {
  setLoading(false)
})
</script>

<template>
  <div
    :class="[
      'flex flex-wrap items-center justify-center gap-4',
      { 'flex-row': identities.some((identity) => identity.image), 'flex-col': !identities.some((identity) => identity.image) },
    ]"
  >
    <template v-for="identity in identities" :key="identity.id">
      <AButton v-if="!identity.image" :id="identity.id" class="integration-button" variant="outline" @click="() => onClickIdentity(identity)">
        {{ identity.name }}
      </AButton>
      <button v-if="identity.image" :id="identity.id" class="integration-image" :title="identity.name" @click="() => onClickIdentity(identity)">
        <img :alt="identity.name" :src="identity.image" />
      </button>
    </template>
  </div>
</template>

<style scoped>
.integration-button {
  @apply w-full select-none;
}

.integration-image {
  @apply w-fit h-12 cursor-pointer select-none;

  img {
    @apply w-full h-full;
    @apply rounded-sm;
  }
}
</style>
