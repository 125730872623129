import type { KeycloakInitOptions } from 'keycloak-js'

import { isLocal } from '@/environments'

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
  responseMode: 'fragment',
  flow: 'standard',
  pkceMethod: 'S256',
  enableLogging: isLocal,
  checkLoginIframe: true,
}
