import { useFavicon } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { type Ref, ref } from 'vue'

import { Portal } from '@/models'
import type { Settings } from '@/models/Setting'
import { type Locale, setI18nLocale } from '@/plugins/i18n'
import { useAppStore } from '@/stores/app.ts'
import { useAuthStore } from '@/stores/auth'
import { useIdleStore } from '@/stores/idle'
import { usePagesStore } from '@/stores/pages'
import { useWidgetsStore } from '@/stores/widgets'

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const settings: Ref<Partial<Settings>> = ref({})
    const hasFetched: Ref<boolean> = ref(false)
    const locale: Ref<LocaleSetting> = ref('nl')

    function setHasFetched(value: boolean = true) {
      hasFetched.value = value
    }

    function setSettings(value: Partial<Settings> | undefined) {
      settings.value = { ...settings.value, ...value }
    }

    function setLocale(value?: LocaleSetting | undefined) {
      if (value) locale.value = value

      setI18nLocale(locale.value)
    }

    function get<T extends keyof Settings>(key: T): Settings[T] | undefined {
      if (!settings.value) return

      // eslint-disable-next-line security/detect-object-injection
      return settings.value[key]
    }

    async function refresh(): Promise<void> {
      const auth = useAuthStore()
      const widgets = useWidgetsStore()
      const pages = usePagesStore()
      const idle = useIdleStore()

      if (!auth.isAuthenticated) return
      setSettings(await Portal.settings(get('version')))
      widgets.setItems(get('widgets'))
      idle.threshold = (get('idle.seconds') || 0) * 1000 // Convert seconds to milliseconds
      pages.setItems(get('pages'))

      setHasFetched()
    }

    async function teapot(versionId?: string): Promise<void> {
      const app = useAppStore()

      setSettings(await Portal.teapot(versionId))

      app.setTitle(get('title'))

      if (get('favicon')) {
        useFavicon(get('favicon'))
      }
    }

    return { get, hasFetched, refresh, settings, setHasFetched, teapot, setSettings, locale, setLocale }
  },
  {
    persist: {
      pick: ['locale'],
      storage: localStorage,
      afterHydrate(context) {
        context.store.setLocale(context.store.$state.locale)
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
}

export type ThemeSetting = 'light' | 'dark'
export type LocaleSetting = Locale
