import type { Matter } from '@/models/Matter'
import { Crm } from '@/plugins/http'

// #region Interfaces

export type TaskActionableDocumentType = 'request_information' | 'upload' | 'start_form'

export type ProcessGroupTemplateActionableSettingsType = {
  document_action?: TaskActionableDocumentType
  permission_action?: null
  form_id?: string
}

export type TaskType = 'consent' | 'document' | 'form'

export type TaskStatus = 'pending' | 'finished' | 'canceled' | 'initial'

export type TaskIncludes = 'matter' | 'process' | 'actionable' | 'status'

export type TaskMetaIncludes = never

export type TaskSorts = never

export type TaskFilters = {
  is_done?: boolean
  is_consent?: boolean
  matter?: string
}

export type TaskCreatePayload = {
  never: never
}

export type TaskUpdatePayload =
  | {
      is_completed?: boolean
      media?: string[]
    }
  | {
      is_canceled?: boolean
      media?: string[]
    }

export type TaskPutPayload = {
  never: never
}

// #endregion

export class Task extends Crm<TaskFilters, TaskCreatePayload, TaskUpdatePayload, TaskPutPayload, TaskIncludes, TaskMetaIncludes, TaskSorts> {
  // #region Class properties

  protected $endpoint = '/customers/tasks'
  protected $primaryKey: keyof this = 'id'
  protected $fillable: Array<keyof this> = []

  // #endregion

  // #region General attributes

  public id!: string
  public name!: string
  public description!: string | null
  public created_at!: string
  public completed_at!: string
  public status!: TaskStatus
  public type!: TaskType
  public form!: null | {
    id: string
    url: string
  }

  // #endregion

  // #region Default attributes
  // #endregion

  // #region Included attributes
  public matter?: Matter
  // #endregion

  // #region Relationship methods
  // #endregion

  // #region Endpoint methods
  // #endregion

  // #region Class methods
  // #endregion

  // #region Async methods (api calls only)
  // #endregion

  // #region Attribute getters & Setters
  // #endregion

  // #region Getters & Setters
  // #endregion
}

// #region Enums
// #endregion

// #region Constants
// #endregion
