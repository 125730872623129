export type AuthDriver = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any

  login(username: string, password: string): Promise<void>
  logout(): Promise<void>
  /** Returns the current session if one is available. */
  session(): Promise<User | null>
  /** Refreshes the current session if possible. */
  refresh(minValiditySeconds: number): Promise<void>
}

import KeycloakDriver from '@/auth/KeycloakDriver'
import TokenDriver from '@/auth/TokenDriver'
import type { User } from '@/models'

const useAuthDriver = () => {
  const type = 'oauth2'
  const keycloak = KeycloakDriver
  const token = TokenDriver

  function getDriver(): AuthDriver {
    return token
  }

  function login(username: string, password: string): Promise<void> {
    return getDriver().login(username, password)
  }

  function logout(): Promise<void> {
    return getDriver().logout()
  }

  return { driver: getDriver(), login, logout, keycloak, token, type }
}

export { useAuthDriver }
